import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { useState } from 'react';
  import axios from "axios";
  import { ChakraProvider } from "@chakra-ui/react";
  import { mode } from "@chakra-ui/theme-tools";
  import { extendTheme } from "@chakra-ui/theme-utils";
import { Link, useNavigate } from 'react-router-dom';

import { useContext } from 'react';
import { UserContext } from '../Hooks/UserContext.jsx';

  
  export default function Login() {

    const styles = {
      global: (props) => ({
        body: {
          color: mode("gray.800", "#4b587c")(props),
          bg: mode("gray.100", "#eef2ff")(props),
        },
    }),
  };
    
    const config = {
      initialColorMode: "dark",
      useSystemColorMode: true,
    };
    
    const colors = {
      gray: {
          light: "#616161",
          dark: "#eef2ff",
      },
    };
    
    const theme = extendTheme({ config, styles, colors });

    const [inputs, setInputs] = useState({
      email: "",
      password: "",
    });
   // const { setUser } = useContext(UserContext);
   const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
  
      try {
        const response = await axios.post('http://localhost:8000/auth/login', inputs, {
      withCredentials: true, // Include cookies in the request
    });
  
        // Check if the signup was successful (you can customize this based on your API response)
        if (response.status === 200) {
          console.log('Signin successful!');
          window.localStorage.setItem("_id", response.data._id);
          window.localStorage.setItem("token", response.data.token); // Store the JWT token
          setUser(response.data); // Update the user state
          navigate("/home");
          console.log("Stored user ID:", window.localStorage.getItem("_id"));
        }
      
      

      } catch (error) {
        console.error('Error during signin:', error);
        // Handle signup failure, e.g., show an error message to the user.
      }
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
    };

    return (
      <ChakraProvider theme={theme}>
       <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <form onSubmit={handleSubmit}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Join Our Pilot Program</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              Once Sign Up Is Completed You Will be granted access
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Full Name*</FormLabel>
                <Input type="name"
          name="name"
          value={inputs.name}
          onChange={handleInputChange}  />
              </FormControl>
              <FormControl>
                <FormLabel> Work Email Address*</FormLabel>
                <Input type="email"
          name="email"
          value={inputs.email}
          onChange={handleInputChange}  />
              </FormControl>
              <FormControl >
                <FormLabel>Current Work Role*</FormLabel>
                <Input type= 'password'
                name="password"
                value={inputs.password}
                onChange={handleInputChange} />
              </FormControl>
              <FormControl >
                <FormLabel>Potential Use Case*</FormLabel>
                <Input type= 'password'
                name="password"
                value={inputs.password}
                onChange={handleInputChange} />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox>I Don't Have an Account</Checkbox>
                  <Button>
                  <Link to="/Signup">
                  <Text color={'blue.400'}>Sign Up</Text>
                  </Link>
                  
                  </Button>
                  
                </Stack>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox>Remember me</Checkbox>
                  <Text color={'blue.400'}>Forgot password?</Text>
                </Stack>
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={handleSubmit}
                  >
                  Join Pilot
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        </form>
        
      </Flex>
   
    </ChakraProvider>
     
    );
  }