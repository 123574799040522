import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
  } from '@chakra-ui/react';
  import { useState } from 'react';
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
  import axios from "axios";
  import { ChakraProvider } from "@chakra-ui/react";
  import { mode } from "@chakra-ui/theme-tools";
  import { extendTheme } from "@chakra-ui/theme-utils";
  import {useNavigate} from 'react-router-dom';
 
  
  export default function SignupCard() {
    const styles = {
      global: (props) => ({
          body: {
            color: mode("gray.800", "#4b587c")(props),
            bg: mode("gray.100", "#eef2ff")(props),
          },
      }),
    };
    const navigate = useNavigate();
    const config = {
      initialColorMode: "dark",
      useSystemColorMode: true,
    };
    
    const colors = {
      gray: {
          light: "#616161",
          dark: "#eef2ff",
      },
    };
    
    const theme = extendTheme({ config, styles, colors });
    
    const [showPassword, setShowPassword] = useState(false);
    const [inputs, setInputs] = useState({
		name: "",
		username: "",
		email: "",
		password: "",
	});

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await axios.post('http://localhost:8000/auth/signup', inputs); 

      // Check if the signup was successful (you can customize this based on your API response)
      if (response.status === 201) {
        console.log('Signup successful!');
        // Redirect the user to a success page or perform any other necessary actions.
      }
      navigate("/Login");

    } catch (error) {
      console.error('Error during signup:', error);
      // Handle signup failure, e.g., show an error message to the user.
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  
    return (
      <ChakraProvider theme={theme}>
       <Flex
   
   minH={'100vh'}
   align={'center'}
   justify={'center'}
   bg={useColorModeValue('gray.50', 'gray.800')}>
   <form onSubmit={handleSubmit}>
   <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
     <Stack align={'center'}>
       <Heading fontSize={'7xl'} textAlign={'center'}>
         Sign up
       </Heading>
       <Text fontSize={'lg'} color={'gray.600'}>
         to Unlock AI automations you never knew existed ✌️
       </Text>
     </Stack>
     <Box
       rounded={'lg'}
       bg={useColorModeValue('dark', 'gray.700')}
       boxShadow={'lg'}
       p={8}>
       <Stack spacing={4}>
         <HStack>
           <Box>
             <FormControl isRequired>
               <FormLabel>Full Name</FormLabel>
               <Input type="text"
     name="name"
     value={inputs.name}
     onChange={handleInputChange} />
             </FormControl>
           </Box>
           <Box>
             <FormControl isRequired>
               <FormLabel>Username</FormLabel>
               <Input type="text"
     name="username"
     value={inputs.username}
     onChange={handleInputChange}/>
             </FormControl>
           </Box>
         </HStack>
         <FormControl isRequired>
           <FormLabel>Email address</FormLabel>
           <Input type="email"
     name="email"
     value={inputs.email}
     onChange={handleInputChange} />
         </FormControl>
         <FormControl isRequired>
           <FormLabel>Password</FormLabel>
           <InputGroup>
             <Input type={showPassword ? 'text' : 'password'}
     name="password"
     value={inputs.password}
     onChange={handleInputChange} />
             <InputRightElement h={'full'}>
               <Button
                 variant={'ghost'}
                 onClick={() => setShowPassword((showPassword) => !showPassword)}>
                 {showPassword ? <ViewIcon /> : <ViewOffIcon />}
               </Button>
             </InputRightElement>
           </InputGroup>
         </FormControl>
         <Stack spacing={10} pt={2}>
           <Button
             loadingText="Submitting"
             size="lg"
             bg={'blue.400'}
             color={'#4b587c'}
             _hover={{
               bg: 'blue.500',
               
             }}
             onClick={handleSubmit}
             >
             Sign up
           </Button>
         </Stack>
         <Stack pt={6}>
           <Text align={'center'}>
             Already a user? <Link color={'blue.400'}>Login</Link>
           </Text>
         </Stack>
       </Stack>
     </Box>
   </Stack>
   </form>
   
   
 </Flex>
       </ChakraProvider>
     
    );
  }