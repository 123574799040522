import React, { useState } from "react";
import "./Createpost.css";
import Navbar from "../../Navbar/Navbar.jsx";
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../Hooks/UserContext.jsx';
import { useGetUserID } from "../../Hooks/getuseridhook.js";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from 'react-router-dom';

function App() {
  const { user } = useContext(UserContext);
  
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    tags: "",
    tools: "",
    role: "",
    steps: [{ img: "", text: "" }],
    postedBy: user._id,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStepChange = (index, e) => {
    const { name } = e.target;
    const value = e.target.files ? e.target.files[0] : e.target.value;
    const newSteps = [...formData.steps];
    newSteps[index][name] = value;
    setFormData({ ...formData, steps: newSteps });
  };

  const handleAddStep = () => {
    setFormData({
      ...formData,
      steps: [...formData.steps, { image: "", text: "" }],
    });
  };
  const navigate = useNavigate();
  const token = window.localStorage.getItem("token"); // 
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create a FormData instance
    const data = new FormData();
  
    // Append all form fields to the FormData instance
    Object.keys(formData).forEach((key) => {
      if (key === 'steps') {
        // If the field is 'steps', stringify the array and append it as a single field
        data.append('steps', JSON.stringify(formData.steps));
      } else {
        // For all other fields, just append them as they are
        data.append(key, formData[key]);
      }
    });
  
    // Append each image file under 'steps' as a separate field
    formData.steps.forEach((step, index) => {
      data.append(`steps[${index}].img`, step.img);
    });
  
    try {
      const response = await axios.post('http://localhost:8000/live/create', data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status === 201) {
        navigate("/home");
      }
  
      console.log(response.data);
    } catch (error) {
      console.log(token);
      console.log(user._id);
  
      console.error(error);
    }
  };

  return (
    
    <div>
    <Navbar/>
      <div className="App">
    
      <form onSubmit={handleSubmit}>

      <div className="input-row" >
  <label>Title</label>
  <textarea
    name="title"
    placeholder="How to reduce churn with AI"
    value={formData.title}
    onChange={handleInputChange}
    maxLength="50"
    rows="1"
  />
  <p>{formData.title.length}/50</p>
</div>
<div className="input-row">
  <label>Description</label>
  <textarea
    name="description"
    value={formData.description}
    onChange={handleInputChange}
    maxLength="150"
    rows="3"
  />
  <p>{formData.description.length}/150</p>
</div>
<div className="input-row">
<label>Tags</label>
  <select name="tags" onChange={handleInputChange}>
 
    <option value=""></option>
    <option value="Technology">Technology</option>
    <option value="Media">Media</option>
    <option value="Telecom">Telecom</option>
    <option value="Legal">Legal</option>
    <option value="Consumer goods">Consumer goods</option>
    <option value="Retail">Retail</option>
    <option value="Online/Digital goods">Online/Digital goods</option>
    <option value="Education">Education</option>
    <option value="Financial services">Financial services</option>
    <option value="Other">Other</option>
  </select>
  <label>Tools</label>
  <select name="tools" onChange={handleInputChange}>
    <option value=""></option>
    <option value="Technology, media and telecom">Technology, media and telecom</option>
    <option value="Financial services">Financial services</option>
    <option value="legal ">legal </option>
    <option value="Consumer goods and retail">Consumer goods and retail</option>
  </select>
  <label>Role</label>
  <select name="role" onChange={handleInputChange}>
    <option value=""></option>
    <option value="Customer Support">Customer Support</option>
    <option value="Marketing">Marketing</option>
    <option value="Sales">Sales</option>
    <option value="Analytics & Insights">Analytics & Insights</option>
    <option value="Design">Analytics & Insights</option>
    <option value="Engineering">Analytics & Insights</option>
    <option value="Analytics & Insights">Analytics & Insights</option>

  </select>
</div>
{formData.steps.map((step, index) => (
  <div className="input-row" key={index}>
    <div className="step-image">
  <label className="labelInput" htmlFor="stepImage">
    +
  </label>
  <input
  id="stepImage"
  className="fileInput"
  type="file"
  name="img"
  accept="image/*"
  onChange={(e) => handleStepChange(index, e)}
/>
</div>
    <div className="step-text">
      <label>Text  </label>
      

      <textarea
        type="text"
        name="text"
        value={step.text}
        onChange={(e) => handleStepChange(index, e)}
      />
    </div>
  </div>
))}
<button type="button" onClick={handleAddStep}>
  Add More
</button>
<button type="button" onClick={handleSubmit}>
  Submit
</button>
</form>
  </div>
  
    </div>
    
  );
}

export default App;
