import React from "react";
import './company.css'
import Navbar from "../Navbar/Navbar";
import Compimg from "./companyimg.png"
import Accordion1 from './compacc1.jsx'


export default function Company() {
  return (
    
    <div className="company-back">
        
        <div className="company-1">
            <div className="company-2">
                <div className="company-3">
                    <div className="company-con">
                        <div className="comp-conh1">
                            <div className="comp-con-1h1">
                                <h1 className="comp-h1">
                                    Company
                                </h1>
                            </div>
                        </div>
                        <div className="comp-conp">
                            <div className="comp-con1p">
                                <div className="comp">
                                Our mission is straightforward: large language models have demonstrated artificial intelligence's potential for human-like reasoning. We aim to harness this capacity for autonomous decision-making to wholly displace traditional job functions, not merely provide incremental productivity gains. Rather than sell software, we sell the work itself.
                                </div>
                                <div className='btn-div-1'>
                            <a className='btn-a' href="https://medium.com/@samnass/we-sell-work-not-software-6f22d7867802">
                                <div className='btn-div-2'>
                                    <div className='btn-div-3'>
                                        <div className='btn-div-4'>
                                            <div className='btn-div-5'>
                                                <span className='btn-span'>
                                                    <span className='bbtn-span-1'>
                                                       Learn More
                                                       <span className='arrow'> &#8594; </span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>     
                            </div>
                        </div>
                        <div className='comp-fig'>
                    <div className='comp-1img'>
                    <img  alt="hero" src={Compimg} className='main-mc-cs-1-img' />
                    </div>
                </div>
                   
                                
                           
                       
                        
                    </div>
                </div>
            </div>
          <Accordion1/>
        </div>
    </div>
    
      
    
  )
}