import Signup from './auth/Signup';
import Login from './auth/Login';
import Homepage from './Homepage/Homepage';
import Profilepage from './Profilepage/Profilepage';
import Postpage from './postpage/Postpage'
import Financialservices from './Financial-services/Financialservices';
import Parking from './Parking/Parking1';
import Pricing from './pricing/pricing1';
import Createpost from './Components/Createpost/Createpost';
import Company from './Company/company.jsx';
import Case from './casestudies/case.jsx'


import React from 'react';
import {BrowserRouter, Route , Routes} from 'react-router-dom';
import { UserProvider } from './Hooks/UserProvider.jsx';




function App() {
  
  return (
 <UserProvider>

<div>
 
 <BrowserRouter>
  <Routes>
  <Route index element={<Homepage/>}/>
  <Route path='/company' element={<Company/>}/>
  <Route path='/casestudies' element={<Case/>}/>
  <Route path='/home' element={<Homepage/>}/>
  <Route path='/createpost' element={<Createpost/>}/>
  <Route path="/Login" element={<Login />} />
  <Route path="/Signup" element={<Signup />} />
  <Route path="/Profilepage" element={<Profilepage />} />
  <Route path="/postpage" element={<Postpage />} />
  <Route path="/financial-services" element={<Financialservices/>} />
  <Route path="/parking" element={<Parking/>} />
  <Route path="/pricing" element={<Pricing/>} />
  </Routes>
 

  </BrowserRouter>

 
  </div>
    
 </UserProvider>
    
   
  );
}

export default App;