/* Mission.jsx */

import React from 'react';
import './mission.css';
import hero from './5643828.jpg'

function Mission() {
 return (
   <div className="w-screen h-screen text-white mission-background">
     <div className="container">
       <img className="hero-image" alt="hero" src={hero} />
       <div className="text-center">
         <h1 className="my-4 text-5xl font-bold leading-tight">
         Accelerated Productivity Through LLM systems
         </h1>
         <p className="text-2xl mb-8">
         The advancements in llms  pose a technological inflection point that needs to be taken advantage of.
         </p>
         <p className="text-2xl mb-8">
         Our mission is to build performant llm systems & applications that take over 80% of any given job function
         </p>
         <div className="flex justify-center mx-auto">
           <button
             className="hover-underline bg-white text-gray-800 font-bold rounded-full py-4 px-8">
             View Projects
           </button>
           <button
             className="ml-4 hover-underline bg-white text-gray-800 font-bold rounded-full py-4 px-8">
             Plugins
           </button>
         </div>
       </div>
     </div>
   </div >
 );
}

export default Mission