import React from 'react';
import Navbar from '../Navbar/Navbar.jsx';
import Component1 from '../Components/Component1/Component1.jsx';
import Component2 from '../Components/Component2/Component2.jsx';
import './Homepage.css'
import Genoffers from '../Components/gen-offers.jsx';
import Footer from '../Footer/Footer.jsx';
import Banners from '../Components/banners.jsx';
import Display from '../Components/Component1/Component11.jsx'
import Why from '../Components/Component2/Why.jsx'
import Displa from '../Components/Component2/Display.jsx';
import Timeline from '../Components/Component2/Timeline.jsx';
import Tools from '../Components/mission/mission.jsx'
import Tells from '../Components/mission/missio2.jsx'

export default function Homepage(){
  return(
    <div>
      <Navbar/>
      
      <Component1/>
      <Banners/>
      <Why/>
      <Tells/>
      <Timeline/>
      <Footer/>
    </div>
  );
}