

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Heading,
  useColorModeValue,
  Text,
  Container,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

export default function SimpleAccordion() {
  return (
    
    
    
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      justifyContent="center" alignItems="center" flexDirection="column"
      bg={useColorModeValue('gray.50', 'gray.800')}>
        <Heading
         mt={'-150px'}
        fontWeight={600}
        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
        lineHeight={'110%'}>
        We Are {' '}
        <Text as={'span'} color={'gray.400'}>
          Hiring
        </Text>
      </Heading>
        
        
      <Container>
       
      
        <Accordion allowMultiple width="100%" maxW="lg" rounded="lg">
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">Founding Cloud Engineer</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
              The interested person would Design, implement, and monitor resource usage, costs, availability in cloud (AWS/Azure/GCP).
Maintain infrastructure efficiency, security, reliability, and fault tolerance.
Automate provisioning tasks, write infrastructure-as-code templates. If you have been known to show exceptional ability 
email samnass@millennialinsights.co
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">Founding Full Stack Engineer</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
              The interested person would Design, build, and maintain scalable web applications from front end to back end.
Collaborate with product and design teams to understand requirements and implement new features.
Optimize performance for speed and scalability. If you have been known to show exceptional ability 
email samnass@millennialinsights.co
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">Founding Research Scientist</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
              The interested person would Lead research initiatives in computer vision, NLP, reinforcement learning, etc.
Publish academic papers, write patents, and present research at top-tier conferences.
Guide engineering teams to develop scalable, robust implementations of research. 
If you have been known to show exceptional ability 
email samnass@millennialinsights.co
             
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">Founding Machine Learning Engineer</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
              The interested person would Build custom machine learning models and algorithms to extract insights from data,
Design and optimize data pipelines, data infrastructure, and feature engineering,
Identify new opportunities where ML/AI can add value and prototypes solutions. If you have been known to show exceptional ability 
email samnass@millennialinsights.co
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">Founding General Purpose Researcher</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
              The interested person would Conduct literature reviews and analyze research across variety of fields,
Identify interesting research problems and gaps for further investigation,
Write proposals and reports to summarize research methodology and findings. If you have been known to show exceptional ability 
email samnass@millennialinsights.co
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Flex>
  )
}