import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Marquee from "react-marquee-slider";
import './banner.css'

import azure from './logo1.svg'
import openai from './logo2.svg'
import aws from './logo3.svg'
import huggingface from './logo4.svg'
import firebase from './logo5.svg'
import nvidia from './logo6.svg'





export default function banners() {
    



    
  return (
    <section className="ban-sec">
      <div className="container">
      <p className="why-serv">Our Providers</p>
        <Marquee className="marquee" velocity={25}>
  <img src={azure} alt="Logo 1" className="logs-ban" />
  <img src={openai} alt="Logo 2" className="logs-ban" />
  <img src={aws} alt="Logo 3" className="logs-ban" />
  <img src={huggingface} alt="Logo 4" className="logs-ban" />
  <img src={firebase} alt="Logo 5" className="logs-ban" />
  <img src={nvidia} alt="Logo 6" className="logs-ban" />
</Marquee>
      </div>
        
    </section>
    
      
    
  )
}