import './missio2.css'
import hero from './5643828.jpg'
import AOS from 'aos';
import Missy from './missio23.png'
import Missy1 from './missio33.png'
import { Link, useNavigate } from "react-router-dom";




function missio2() {
    AOS.init();
    return (
      <div>
        <section className='sec'>
            <div className='coh1' data-aos="fade-up" data-aos-duration="2000" >
            <div className='wrapdiv'>
            <div className='coh2'>
            <div className='coh3'>
                <div className='co1'>
                    <div className='co1-2'>
                    <img  alt="hero" src={Missy} className='main-mc-cs-1-img' />
                    </div>
                </div>
                <div className='co2'>
                    <div className='co2-2'>
                        <h2 className='co2-h2' data-aos="fade-up" data-aos-duration="2000">
                        Accelerated Productivity Through LLM systems
                        </h2>
                        <span className='co2-span'>
                            <div className='co2-div' data-aos="fade-up" data-aos-duration="2000">
                                <p className='co2-p' data-aos="fade-up" data-aos-duration="2000">
                                The advancements in llms  pose a technological inflection point that needs to be taken advantage of.
                                Our mission is to build performant llm systems & applications that take over 80% of any given job function
   

                                </p>
                            </div>
                        </span>
                        <div className='btn-div-1'>
                            <a className='btn-a'>
                                <div className='btn-div-2'>
                                    <div className='btn-div-3'>
                                        <div className='btn-div-4'>
                                            <div className='btn-div-5'>
                                                <span className='btn-span'>
                                                    <Link to="/Login">
                                                    <span className='btn-span-1'>
                                                       join pilot
                                                       <span className='arrow'> &#8594; </span>

                                                    </span>
                                                    </Link>
                                                    
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </a>
                        </div>

                    </div>

                </div>
            </div>

        </div>

            </div>
            </div>
            <div className='coh1' data-aos="fade-up" data-aos-duration="2000">
            <div className='wrapdiv'>
            <div className='coh2'>
            <div className='coh3'>
                <div className='co1'>
                    <div className='co1-2'>
                    <img  alt="hero" src={Missy1} className='main-mc-cs-1-img' />
                    </div>
                </div>
                <div className='co2'>
                    <div className='co2-2' data-aos="fade-up" data-aos-duration="2000">
                        <h2 className='co2-h2' data-aos="fade-up" data-aos-duration="2000">
                        Equities Analyst Agent
                        </h2>
                        <span className='co2-span'>
                            <div className='co2-div'>
                                <p className='co2-p' data-aos="fade-up" data-aos-duration="2000">
                                We are embarking on on an ambitious journey to create a first of its kind equities analyst agent system. 
                                Our goal is create a functional system that replicates the work of an equities analysts. 
                                Fully equipped with state control, domain knowledge, skills and tools. 
   

                                </p>
                            </div>
                        </span>
                        <div className='btn-div-1'>
                            <a className='btn-a'>
                                <div className='btn-div-2'>
                                    <div className='btn-div-3'>
                                        <div className='btn-div-4'>
                                            <div className='btn-div-5'>
                                                <span className='btn-span' data-aos="fade-up" data-aos-duration="2000">
                                                    <Link to="/Login">
                                                    <span className='btn-span-1'>
                                                       join pilot
                                                       <span className='arrow'> &#8594; </span>

                                                    </span>
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </a>
                        </div>

                    </div>

                </div>
            </div>

        </div>

            </div>
            </div>
            
            
       
        </section>
        
      </div>
    );
   }
   
   export default missio2