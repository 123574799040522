import React from "react";
import './Why.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, useNavigate } from "react-router-dom";







export default function Why() {
  AOS.init();



    
  return (
    <div className="whycontainer">
      <div className="why1">
        <div className="why2" data-aos="fade-up" data-aos-duration="1000" >
          <h2 className="whyh2" data-aos="fade-up" data-aos-duration="1000">
          LLM Systems That Think Like Employees
          </h2>
          <p className="whyp" data-aos="fade-up" data-aos-duration="1000">Millennial Labs creates LLM systems trained for specialized jobs for you to hire as Remote employees. We focus on job expectation first, then leverage technology to develop systems that integrate with your tools and data to get work done</p>
          <div className='btn-div-1'>
                            <a className='btn-a'>
                                <div className='btn-div-2'>
                                    <div className='btn-div-3'>
                                        <div className='btn-div-4'>
                                            <div className='btn-div-5'>
                                                <span className='btn-span' data-aos="fade-up" data-aos-duration="1000">
                                                  <Link to="/Login">
                                                  <span className='btn-span-1'>
                                                       join pilot
                                                       <span className='arrow'> &#8594; </span>

                                                    </span>
                                                  </Link>
                                                    
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </a>
                        </div>
        </div>

      </div>
    </div>
    
      
    
  )
}