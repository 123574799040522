import axios from 'axios';
import './Postpage.css';
import Navbar from '../Navbar/Navbar.jsx';
import bookmark from './postpage-bookmark.png';
import comment from './postpage-comment.png';
import share from './postpage-share.png';
import ImageSlider from './slidercontent/slidercontent';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';





function Postpage() {
  const slides = [
    { url: "http://localhost:3000/image-1.jpg", title: "beach" },
    { url: "http://localhost:3000/image-2.jpg", title: "boat" },
    { url: "http://localhost:3000/image-3.jpg", title: "forest" },
    { url: "http://localhost:3000/image-4.jpg", title: "city" },
    { url: "http://localhost:3000/image-5.jpg", title: "italy" },
  ];
  const { id } = useParams();
 
  const [post, setPost] = useState(null);
  
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`http://localhost:8000/live/${id}`, {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setPost(response.data.post);
    
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [id]);
  console.log(post);
  
  if (!post) {
    return <div>Loading...</div>;
  }

  
  return (
    <div>
        <Navbar/>
       
        
        <div className='defPostpage'>
        <img src={`http://localhost:8000/${post.steps[0].img}`} alt="" className='defPostpage-img' />
        <h1 className='h1tag'>{post.title}</h1>
        <ul className='category-list'>
        Posted in
        <li><a href='https://www.google.com' className='list'>Image generation</a></li>
        <li><a href='https://www.google.com' className='list'>dalle</a></li>
        <li><a href='https://www.google.com' className='list'>mid journey</a></li>
        <li><button className= "navbarbutton" ><img src={bookmark} alt="" className="imageicon"/></button></li>
        <li><button className= "navbarbutton" ><img src={comment} alt="" className="imageicon"/></button></li>
        <li><button className= "navbarbutton" ><img src={share} alt="" className="imageicon"/></button></li>
        </ul>
        <p className='description'><i>{post.description}</i></p>
         </div>
        
         <div className='postinf0'>
         <img src="https://picsum.photos/id/237/200/300" alt="" className='Postpage-img'></img>
         <h3 className='h3tag'>#3 in image generation</h3>
         </div>
         <div className='postinfo'>
          <h3 className='h3tag-1'>Other Relevant Resources</h3>
          <ul className='relevant'>
        <li><a href='https://www.google.com' className='relevant-list'>Strategies for Minimizing Customer Churn i..</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>dalle</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        <li><a href='https://www.google.com' className='relevant-list'>mid journey</a></li>
        </ul>
         </div>
         <div>
         <div className='postcontainerstyle'>
         <ImageSlider slides={slides} />
         </div>

         </div>
         
         
        
      
    </div>
    
       

    
  )
}

export default Postpage