import React, { useState, useEffect, useRef } from 'react';
import homevideo from './video12.mp4';
import './Component1.css';
import button1 from './button1.png';
import Typed from 'typed.js';
import { Link } from 'react-router-dom';
import yourVideoSource from './videosource.mov'
import Abstract from './abstract1.jpg'
import Hero from './herobg.png'





function Component1() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const el = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['Financial Analysis', 'Investment Analysis', 'Corporate Finance'],
      typeSpeed: 40,
      backSpeed: 40,
      loop: true,
      onComplete: (self) => self.start()
    });

    return () => {
      typed.destroy();
    };
  }, []);

  
  const listItems = ["Financial Services", "Consumer Goods", "Online/Digital goods", "Legal Services",  "Administrative", "Real Estate", "Technology", "Human Resources"]; 

  return (
    <div className='defComponent1'>
   
   <img className="background-image" src={Hero} alt="Background" />
      <img className="mobile-image" src={Hero} alt="Mobileview" />
      <div className="content">
      
        <div className='h1-con'>
        <div className="h1-container">
          <div className='h1-container-1'>
          <h1 className="h1-home">
          Your AI Co-Pilot For 
           <br></br>
           <span className='span-home' ref={el}></span>
        </h1>
        <p className='main-p'> From Prompt To Spreadsheet To Financial Model To Analysis. Your Perfect AI Assistant that reduces Analysis done in Hours to Minutes</p>
          </div>
          <div className='abtn-div-1'>
                            <a className='btn-a-a'>
                                <div className='abtn-div-2'>
                                                <span className='home-btn-span' data-aos="fade-up" data-aos-duration="1000">
                                                  <Link to="/Login">
                                                  <span className='home-btn-span-1'>
                                                       Join Pilot
                                                       <span className='arrow'> &#8594; </span>

                                                    </span>
                                                  </Link>
                                                    
                                                </span>
                                        </div>
                            </a>
                        </div>
        
        </div>
      
      
            
            
            
              
            
             

          </div>
          
        </div>
        
         </div>
    );
}



export default Component1;