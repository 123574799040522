// Filename - components/Footer.js
import Logo from "../postpage/logo_transparent.png";
import React from "react";
import {
	Box,
	FooterContainer,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./Footer1";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
	return (
		<Box>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<img src={Logo} alt="logo" style={{width: '90px', height: '90px', marginRight: '10px'}} /> {/* add your logo */}
				<h1
					style={{
						color: "#4b587c",
						textAlign: "center",
						marginTop: "10px",
					}}
				>
					Millennial Insights links
				</h1>
			</div>
			<FooterContainer>
				<Row>
					<Column>
          <Heading>
			
     
		  <Link to="/research"></Link>
          <FooterLink>
          Research
          </FooterLink>
          </Heading>
					</Column>
					<Column>
						<Heading>
              <FooterLink>
              Case Studies
              </FooterLink>
            
              </Heading>
						
					</Column>
					<Column>
						<Heading>
              <FooterLink>
              Vision
              </FooterLink>
              
              </Heading>
						
					</Column>
					<Column>
						<Heading>
              <FooterLink>
              Contact
              </FooterLink>
             
              </Heading>
						
					</Column>
				</Row>
			</FooterContainer>
		</Box>
	);
};
export default Footer;
