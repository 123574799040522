import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext.jsx';

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userId = window.localStorage.getItem("_id");
      if (userId) {
        try {
          const response = await axios.get(`http://localhost:8000/auth/user/${userId}`);
          setUser(response.data);
        } catch (error) {
          console.error(error);
        }
      }
    };
  
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

 