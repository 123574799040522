import React from 'react'
import './Financialservices.css';
import Navbar from '../Navbar/Navbar.jsx';
import { Link } from 'react-router-dom';


function Financialservices() {
  const listItems = ["Mortgage Broker", "Financial Analyst", "Insurance", "Wealth Management",  "Credit Lending",  "Tax Officer",  "Financial Advisor", "Hedge Funds", "Financial Broker"]; 
  return (
      <div>
          <Navbar/>
          <div className='cover'>
              <div className='centered-content'>
                  <div className="word-list">
                      {listItems.map((item, index) => (
                          <div key={index} className="word">
                              {item === "Financial Services" ? (
                                  <Link to="" className="link-no-underline">{item}</Link>
                              ) : (
                                  item
                              )}
                          </div>
                      ))}
                  </div>
                  <h1 className='h1-home-finserv'> Deploy AI Agents Trained On Your Company's Data </h1>
              </div>
              <ul className='ul-vertical'>
                  <li >
                      <h2 className='h1tag-fin'>Mortgage Broker</h2>
                      <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Process Automation</h1>
                          <p>Automate repetitive tasks involved in mortgage origination process - application data entry, fee calculation, document generation.</p>
                          <button className='contact-sales'>Contact Sales</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Underwriting Assistance</h1>
                          <p>Automate collection and analysis of applicant financial data from documents to accelerate underwriting and qualification - Digitally scan and extract key data fields from paper documents and forms instead of manual data entry.</p>
                          <button className='contact-sales'>Contact Sales</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Customer Operations/ Management</h1>
                          <p>Use AI to analyze consumer data and identify promising new mortgage lending leads based on income, location, home search activity, and other attributes indicative of mortgage readiness - Automate follow-ups and communications to nurture leads with helpful, personalized mortgage information</p>
                          <button className='contact-sales'>Contact Sales</button>
                      </div>
                      </div>
                      
                  </li>
                  <li>
    <h2 className='h1tag-fin'>Financial Analyst</h2>
    <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Data Collection</h1>
                          <p>Leverage generative writing capabilities to automatically pull relevant data from various systems and populate financial model templates.</p>
                          <button className='contact-sales'>Contact Sales</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Reporting & Analysis</h1>
                          <p>Automate the creation of reports and charts using natural language prompts and data parameters - Use generative writing to produce first drafts of routine memos, notes and status updates</p>
                          <button className='contact-sales'>Contact Sales</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Investment Research/Analysis</h1>
                          <p>Use natural language processing tools to search, read and summarize lengthy financial reports, filings, news articles to gather intel faster</p>
                          <button className='contact-sales'>otaCnct Sales</button>
                      </div>
                      </div>
  </li>
  <li>
    <h2 className='h1tag-fin'>Insurance</h2>
    <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Claims Management</h1>
                          <p>Speed up claims processing with AI techniques like natural language processing and optical character recognition - Use ML prediction models to detect potential fraudulent claims.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Policy Administration</h1>
                          <p>Automate new business on-boarding through virtual agents that can take applications, collect documents, issue policies - Use AI to extract key details from documents and forms to cut down manual data entry -  Automate parts of underwriting by using computer vision to assess property conditions and ML to predict risks.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Customer Operations/ Management</h1>
                          <p>Implement conversational chatbots to handle common customer inquiries and automate call center interactions - Tailor customer communications for retention using natural language generation.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      </div>
  </li>
  <li>
    <h2 className='h1tag-fin'>Wealth Management</h2>
    <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Client Engagement</h1>
                          <p>Build detailed client profiles using natural language processing to analyze client documents and conversations.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Financial Planning</h1>
                          <p>Create personalized financial plans integrated across investment, tax, estate planning using generative writing.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Portfolio Management</h1>
                          <p>Optimize portfolios and test strategies using AI algorithms and simulations before implementation - Automate periodic portfolio rebalancing using parameters customized for each client</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      </div>
  </li>
  <li>
    <h2 className='h1tag-fin'>Credit Lending</h2>
    <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Underwriting</h1>
                          <p>Automate gathering and extracting applicant data from documents for underwriting - Use AI predictive models to rapidly evaluate creditworthiness based on applicant attributes. - Automatically validate applicant employment, income sources, assets to complete underwriting.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Loan Origination</h1>
                          <p>
Digitize the loan application process through AI guided interviews with applicants. - Use AI to autocomplete loan documents and disclosures customized for each applicant. - Automate underwriting decision making for routine loans that fit standardized models.</p>
<button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Customer Operations & Servicing</h1>
                          <p>Use data analytics to identify creditworthy prospects based on attributes like income, employment history and behaviors. - Automate lead follow-up and nurturing with customized offers and loan information sent across channels.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      </div>
  </li>
  <li>
    <h2 className='h1tag-fin'>Tax Officer</h2>
    <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Tax Preparation</h1>
                          <p>Automate data import from various client accounting systems and financial institutions - Use AI to extract and categorize income, deductions, and other tax-related data from client documents. - Automate completion of standard tax forms using client data. - Apply AI to identify gaps or question marks in client data for the tax professional to follow up on. </p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Tax Planning</h1>
                          <p>Generate personalized tax saving recommendations based on analyzing client financial situation. - Model different tax scenarios to optimize client tax liability and cash flow. - Proactively monitor tax law changes and flag clients likely to be impacted.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Client Management/ Support</h1>
                          <p>Digitize client information intake forms to eliminate manual data entry. - Automate request and collection of documents and records needed for tax preparation.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      </div>
  </li>
  <li>
    <h2 className='h1tag-fin'>Financial Advisor</h2>
    <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Data Analysis and Insights</h1>
                          <p>AI models to forecast market trends, portfolio performance, and investment outcomes based on historical data and external factors. - Use AI to assess risk levels for individual clients and provide tailored investment strategies accordingly</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Financial Planning/ Research</h1>
                          <p>Develop AI-powered tools that allow clients to set and track their financial goals, with real-time adjustments based on changing circumstances. - Leverage AI to generate personalized investment recommendations aligned with clients' risk tolerance, financial goals, and time horizon.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Client Interaction and Engagement</h1>
                          <p> Generate personalized, automated reports on portfolio performance, financial goals progress, and investment recommendations - Digitize client information intake forms to eliminate manual data entry. </p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      </div>
  </li>
  <li>
    <h2 className='h1tag-fin'>Hedge Funds</h2>
    <div className='li-horizontal' >
                      <div className='rectangle'>
                          <h1>Investor Relations</h1>
                          <p>Automate client reporting by using AI natural language generation to create personalized summaries. - </p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Investment Analysis/ Research</h1>
                          <p>Use natural language processing of news, filings, and alternative data to generate alpha-generating investment ideas. - Apply natural language processing to track hedge fund letters, presentations, patents etc. to gain insight into competitors' strategies and sentiment. - Automate gathering key details from filings, transcripts, publications to profile companies and speed up research.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      <div className='rectangle'>
                          <h1>Data Collection/ Processing</h1>
                          <p>Generative AI can be used to automatically gather and extract relevant data from lengthy documents like 10-Ks, research reports, news articles etc. This structured data can feed analysis. - scrape and parse huge datasets from non-traditional sources like satellite imagery, shipping data, foot traffic, online prices which can fuel new insights.</p>
                          <button className='contact-sales'>Request Demo</button>
                      </div>
                      </div>
  </li>
  
              </ul>
          </div>
      </div>
  )
}

export default Financialservices