import React from "react";
import nigger from './nigger.jpeg'
import nigger1 from './c3ai-pilot-timeline-graph.svg'
import './Timeline.css'






export default function Timeline() {
    



    
  return (
    <div>
       <div className="timeline1">
        <div className="timeline2">
          <div className="timeline3">
            <h2 className="timelineh2"> Our Pilot Program is built for Speed & Performance.</h2>
          </div>
          <div className="timeline-grid">
            <div className="timeline-grid-1">
              <h3 className="timeline-grid-1-h3">
                Exec Briefings
              </h3>
              <p className="timeline-grid-1-p">2 Hours</p>
            </div>
            <div className="timeline-grid-1">
              <h3 className="timeline-grid-1-h3">
                Database Porting
              </h3>
              <p className="timeline-grid-1-p">30 Mins</p>
            </div>
            <div className="timeline-grid-1">
              <h3 className="timeline-grid-1-h3">
                Preliminary Training
              </h3>
              <p className="timeline-grid-1-p">3 Hours</p>
            </div>
            <div className="timeline-grid-1">
              <h3 className="timeline-grid-1-h3">
                Complete Integration
              </h3>
              <p className="timeline-grid-1-p">10 Mins</p>
            </div>

          </div>

        </div>
       </div>
        
    </div>
    
      
    
  )
}