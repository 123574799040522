import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./Navbar.css";
import bookmark from "./bookmark.png";
import profile from "./profile.png";
import React from "react"
import Logo from "../postpage/logo_transparent.png";
import { useContext } from 'react';
import { UserContext } from '../Hooks/UserContext.jsx';
import { useState } from 'react';
import { FaBars, FaTimes } from "react-icons/fa";







export default function Navbar() {
  const { user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }
    return (
    <nav className='defNavbar'>
      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      <Link to="/home">
      <img src={Logo} alt="Logo" className="logs" />
      </Link>

      <ul className={`nav-menu ${isOpen ? 'open' : ''}`}>

      

      


      <li>
      <Link to="/company">
      <button className= "navbarbutton submitButton">
      <span className="imageicon">Company</span>
        </button>
      </Link>
      

      </li>
      
  <li>
  <Link to={user ? "/Profilepage" : "/Login"}>
  <button className= "navbarbutton submitButton">
    <span className="imageicon">Join Pilot</span>
    </button>
  </Link>
    
  </li>

      </ul>

      
    </nav>
    )
}

        


