import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    Stack,
   Button,
    useColorModeValue,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels
  } from '@chakra-ui/react'
  import Navbar from '../Navbar/Navbar.jsx';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Hooks/UserContext.jsx';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function SocialProfileSimple() {
    // Replace these with actual data
    const userName = "User Name";
    const contributions = ["Post 1", "Post 2", "Post 3"];
    const savedPosts = ["Post A", "Post B", "Post C"];
    
    const navigate = useNavigate();
   const [user, setUser] = useState(null);


   useEffect(() => {
    const fetchUser = async () => {
        const userId = window.localStorage.getItem("_id");
        if (userId) {
            try {
                const response = await axios.get(`http://localhost:8000/auth/user/${userId}`);
                setUser(response.data);
                console.log("Fetching data for user ID:", userId);
                console.log(response.data)
               
            } catch (error) {
                console.error(error);
            }
        }
       
    };
    
    fetchUser();
}, []);
    
    const handleLogout = async () => {
        try {
            await axios.post('http://localhost:8000/auth/logout');
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("_id");
            setUser(null);
            navigate("/home");
            console.log("User ID after logout:", window.localStorage.getItem("_id"));
        } catch (err) {
            console.error(err);
        }
    };
    

    return (
        <div className='profile'>
           <Navbar/>
           <Box borderRadius="1rem" boxShadow="lg" p={5} bg={useColorModeValue('white', 'gray.700')} width="80%"
    height="50%" marginTop={130} marginLeft={50}  >
    <Center marginTop={20}>
        <Stack>
            <Avatar size="2xl" name={userName} borderRadius="full" src="https://images.unsplash.com/photo-1520810627419-35e362c5dc07?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"  />
            <Heading>{user ? user.name : "User Name"}</Heading> 
        </Stack>
    </Center>
    <Tabs isFitted variant="enclosed" >
    <TabList mb="1em" >
        <Tab width="520px">Contributions</Tab>
        <Tab width="520px">Saved</Tab>
    </TabList>
    <TabPanels>
        <TabPanel>
            <Box>
                {contributions.map((post, index) => (
                    <Text key={index}>{post}</Text>
                ))}
            </Box>
        </TabPanel>
        <TabPanel>
            <Box>
                {savedPosts.map((post, index) => (
                    <Text key={index}>{post}</Text>
                ))}
            </Box>
        </TabPanel>
    </TabPanels>
</Tabs>
<Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={handleLogout}
                  >
                  logout
                </Button>
</Box>
        </div>
    )
}